import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`A survey of hospital physicians indicated that when using direct ophthalmoscopy, they correctly diagnosed abnormalities less than 50% of the time.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  