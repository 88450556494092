import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box, Text } from 'grommet';
import ProductIntro from 'components/productIntro';
import SectionTitle from 'components/title/sectionTitle';
import { RequestBar, RequestBarExtended } from 'components/requestBar';
import { ImageStack } from 'components/pageCards';
import Img from 'gatsby-image';

import Intro from '../../content/products/educatorsToolkit/intro.mdx';
import Intro2 from '../../content/products/educatorsToolkit/intro2.mdx';
import Platforms from '../../content/products/educatorsToolkit/platforms.mdx';

const EducatorsToolkit = ({ data }) => (
  <Layout>
    <Head pageTitle={data.educatorsToolkitJson.title} />
    <Box fill="horizontal">
      <SectionTitle title="Educators Toolkit™" />
      <Box width="xlarge" alignSelf="center">
        <ProductIntro>
          <Intro />
        </ProductIntro>
        <RequestBar />
        <ImageStack
          image={data.educatorsToolkitJson.pagePic1}
          textColor="dark-1"
          textAlign="right"
        >
          <Text size="xlarge">
            <Intro2 />
          </Text>
        </ImageStack>
        <Box alignSelf="center" width="large" margin="medium">
          <Img
            fluid={
              data.educatorsToolkitJson.pagePic2
                ? data.educatorsToolkitJson.pagePic2.childImageSharp.fluid
                : {}
            }
            alt="Educators Toolkit"
          />
        </Box>
        <Box
          background="light-1"
          pad={{ right: 'large', left: 'large', bottom: 'small' }}
        >
          <Platforms />
        </Box>
        <RequestBarExtended />
      </Box>
    </Box>
  </Layout>
);

EducatorsToolkit.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EducatorsToolkit;

export const query = graphql`
  query EducatorsToolkitQuery {
    educatorsToolkitJson {
      title
      pagePic1 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic2 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
