import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} >
    An Educators Toolkit™ is available for both the OtoSim 2™ and OphthoSim™ platforms. It comprises of 3 modules:
</Heading>
<MDXTag name="ol" components={components}>
<MDXTag name="li" components={components} parentName="ol"><MDXTag name="strong" components={components} parentName="li">{`Image Creator:`}</MDXTag><MDXTag name="br" components={components} parentName="li"></MDXTag>
{`Instructors can import their own images into the OtoSim 2™ and OphthoSim™ image libraries. Tools are provided to adjust the images to the correct size and orientation in the Base Unit.
`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol"><MDXTag name="strong" components={components} parentName="li">{`Image Annotator:`}</MDXTag><MDXTag name="br" components={components} parentName="li"></MDXTag>
{`Instructors can pre-annotate the features and landmarks on their own images. Students will be able to review the important anatomical landmarks that have been highlighted, and to locate them with the Instrumented Otoscope or Ophthalmoscope.
`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol"><MDXTag name="strong" components={components} parentName="li">{`Slide Presenter:`}</MDXTag><MDXTag name="br" components={components} parentName="li"></MDXTag>
{`Instructors can create customized slide deck presentations for classroom training. Lecture slides can be planned ahead of time. The module includes a Presenter interface to present the slides to up to 14 Base Units directly, or to an unlimited number of units through wireless repeaters. It also allows remote teaching to any internet-connected Base Unit. The Presenter also features live progress feedback to monitor the trainees’ progress during a multi-user setting. `}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  