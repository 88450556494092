import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`The Educators Toolkit™ software upgrade is a set of 3 applications that allow healthcare educators to customize their OtoSim 2™ and OphthoSim™ simulation training sessions. Professors can now add their own ear and eye images, and build their own custom slide deck to teach otoscopy and ophthalmoscopy more effectively.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  